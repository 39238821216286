import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { api } from '@verifime/api-definition';
import {
  ColoredIcon,
  ContentDetailsDisplay,
  enqueErrorNotification,
  PortraitPanel as PortraitPanelComponent,
  PortraitType,
} from '@verifime/components';
import { TIdentityDocumentPortraitProps } from '@verifime/components/src/IndividualPortrait/IdentityDocumentPortrait';
import tokens from '@verifime/design-tokens';
import { useFeatureToggle } from '@verifime/utils';
import { useEffect, useState } from 'react';
import DocumentUploader from '../DocumentUploader';
import useLivenessPanel from '../hooks/useLivenessPanel';
import { TaskRequestPanel } from './TaskRequestPanel';

type TFaceComparisonResultPanelProps = Parameters<
  typeof PortraitPanelComponent
>[0]['faceComparisonResultProps'];
type TPortraitComparisonResponse = {
  similarity?: number;
  result?:
    | 'COMPLETED'
    | 'MORE_THAN_ONE_FACE_DETECTED'
    | 'NO_FACE_DETECTED'
    | 'SOURCE_IMAGE_CONFIDENCE_LOWER_THAN_MINIMAL'
    | 'TARGET_IMAGE_CONFIDENCE_LOWER_THAN_MINIMAL';
};

const UploadIdPortrait = ({
  customerId,
  onUploadSuccess,
}: Readonly<{
  customerId: string;
  onUploadSuccess?: () => void;
}>) => {
  const handleSubmit = async (fileId: string) => {
    api
      .postV2personIdportrait(
        {
          fileId: fileId,
        },
        {
          params: { id: customerId },
        },
      )
      .then(() => {
        onUploadSuccess?.();
      })
      .catch(() => {
        enqueErrorNotification('Something went wrong, please try again.');
      });
  };

  return (
    <DocumentUploader
      entityId={customerId}
      documentType="IdPortrait"
      onSubmit={(fileId) => handleSubmit(fileId)}
    />
  );
};

const UploadIdPortraitDrawer = ({
  action,
}: Readonly<Required<Pick<TIdentityDocumentPortraitProps, 'action'>>>) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!action) {
    return;
  }

  return (
    <Box sx={{ marginTop: tokens.spacingBase }}>
      <Button variant="outlined" startIcon={<UploadFileIcon />} onClick={() => setIsOpen(true)}>
        Upload another ID Portrait
      </Button>
      <ContentDetailsDisplay
        PaperProps={{ sx: { width: 'auto' } }}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Stack>
          <Box sx={{ alignSelf: 'end' }}>
            <IconButton onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          {action}
        </Stack>
      </ContentDetailsDisplay>
    </Box>
  );
};

function compareFacesResultMessage(response: TPortraitComparisonResponse) {
  if (!response.result) {
    return;
  }

  switch (response.result) {
    case 'MORE_THAN_ONE_FACE_DETECTED':
      return 'More than one face detected';
    case 'NO_FACE_DETECTED':
      return 'No similar face detected';
    case 'SOURCE_IMAGE_CONFIDENCE_LOWER_THAN_MINIMAL':
    case 'TARGET_IMAGE_CONFIDENCE_LOWER_THAN_MINIMAL':
      return 'No face detected';
  }
}

function comparisonPanelPropFromResponse(
  response: TPortraitComparisonResponse,
): Partial<TFaceComparisonResultPanelProps> {
  if (response.similarity != null) {
    return {
      similarityPercentage: Math.floor(response.similarity),
    };
  } else {
    return {
      statusMessage: compareFacesResultMessage(response),
    };
  }
}

export default function PortraitPanel({
  customerId,
  showStatus,
  showUploadIdPortriat,
  videoDownloadErrorMessage,
}: Readonly<{
  customerId: string;
  showStatus?: boolean;
  showUploadIdPortriat?: boolean;
  videoDownloadErrorMessage?: string;
}>) {
  const { isShowFaceComparisonResult } = useFeatureToggle();

  const [activeTab, setActiveTab] = useState<PortraitType>(PortraitType.LIVENESS);
  const [key, setKey] = useState(false);
  const [openRequestTaskLinkPanel, setOpenRequestTaskLinkPanel] = useState(false);
  const [faceComparisonResultPanelProps, setFaceComparisonResultPanelProps] =
    useState<TFaceComparisonResultPanelProps>({
      statusMessage: 'No face comparison result',
    });

  const {
    latestTaskMetadata,
    fetchLivenessTransaction,
    generateVideoDownloadUrl,
    handleFaceCheckRequest,
  } = useLivenessPanel({ customerId });

  const handleOnClose = () => {
    setOpenRequestTaskLinkPanel(false);
  };
  useEffect(() => {
    if (isShowFaceComparisonResult && customerId) {
      api
        .getV1personCustomerIdportraitcomparison({ params: { customerId } })
        .then((response) => {
          setFaceComparisonResultPanelProps(comparisonPanelPropFromResponse(response));
        })
        .catch(() => undefined);
    }
  }, [customerId]);

  const fetchIdentityDocumentPortrait = async () => {
    return api
      .getV2personIdportrait({
        params: { id: customerId },
      })
      .then((response) => response.portraitUrl)
      .catch(() => undefined);
  };

  return (
    <>
      <PortraitPanelComponent
        // To make sure reload when uploaded success
        key={activeTab + key}
        initialActiveTab={activeTab}
        faceComparisonResultProps={
          isShowFaceComparisonResult ? faceComparisonResultPanelProps : undefined
        }
        livenessPanelProps={{
          showStatus,
          fetchLivenessTransaction,
          generateVideoDownloadUrl,
          videoDownloadErrorMessage,
          requestDetail: {
            onRequest: handleFaceCheckRequest,
            onShowTaskRequestLink: latestTaskMetadata
              ? () => setOpenRequestTaskLinkPanel(true)
              : undefined,
            requestBy: latestTaskMetadata?.createdBy,
            requestDateTime: latestTaskMetadata?.createdDateTime,
          },
        }}
        identityDocumentPortraitProps={{
          fetchIdentityDocumentPortrait,
          action: showUploadIdPortriat && (
            <UploadIdPortrait
              customerId={customerId}
              onUploadSuccess={() => {
                setKey(!key);
                setActiveTab(PortraitType.IDENTITY_DOCUMENT);
              }}
            />
          ),
          footer: (
            <UploadIdPortraitDrawer
              action={
                showUploadIdPortriat && (
                  <UploadIdPortrait
                    customerId={customerId}
                    onUploadSuccess={() => {
                      setKey(!key);
                      setActiveTab(PortraitType.IDENTITY_DOCUMENT);
                    }}
                  />
                )
              }
            />
          ),
        }}
      />
      {latestTaskMetadata && (
        <Dialog open={openRequestTaskLinkPanel} onClose={handleOnClose}>
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Show Request Link</Typography>
              <Stack direction="row" justifyContent="flex-end">
                <IconButton aria-label="close" onClick={handleOnClose}>
                  <ColoredIcon iconName="closeRounded" />
                </IconButton>
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <TaskRequestPanel taskId={latestTaskMetadata.id} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
